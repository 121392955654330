.testimonials-card-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.testimonials-card-image1 {
  width: 100px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.testimonials-card-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.testimonials-card-image2 {
  top: 81px;
  left: 46px;
  right: auto;
  width: 15px;
  bottom: auto;
  object-fit: cover;
}
.testimonials-card-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.testimonials-card-text1 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.testimonials-card-text3 {
  color: var(--dl-color-gray-700);
}
