.link-icon-button-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.link-icon-button-button {
  color: var(--dl-color-purple-default);
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
}
.link-icon-button-icon1 {
  fill: var(--dl-color-purple-default);
  width: 20px;
  align-self: center;
}
